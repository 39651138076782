import "./testimonial.scss"

function Testimonial() {
  return (
    <div className='testimonial' id='testimonials'>
      Testimonial
    </div>
  )
}

export default Testimonial