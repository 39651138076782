import "./topbar.scss";
import Logo from "../../assets/logo/logo_green_white_outline.svg";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import { useState } from "react";

export default function Topbar({ menuOpen, setMenuOpen }) {
  return (
    <div className={"topbar " + (menuOpen && "active")}>
      <div className="wrapper">
        <div className="left">
          <a href="#intro">
            <img src={Logo} alt="" className="logo" />
          </a>
          <div className="mid">
            <PersonIcon className="icon" />
            <span>+32 489 89 62 35</span>
          </div>
          <div className="mid">
            <EmailIcon className="icon" />
            <span>enderyuruk9932@gmail.com</span>
          </div>
        </div>

        <div className="right">
          <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
      </div>
    </div>
  );
}
