import "./app.scss";
// import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import Topbar from "./Components/Topbar/Topbar";
import Intro from "./Components/Intro/Intro";
import Works from "./Components/Works/Works";
import Portfolio from "./Components/Portfolio/Portfolio";
import Testimonial from "./Components/Testimonials/Testimonial";
import Contact from "./Components/Contact/Contact";
import Menu from "./Components/menu/Menu";
import Test from "./Components/test/Test";
import { useState } from "react";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="app">
      <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
      <div className="sections">
        <Intro />
        <Portfolio />
        <Works />
        <Testimonial/>
        <Contact />
      </div>
    </div>
  );
}

export default App;
