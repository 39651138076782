import "./intro.scss";
import prof from "../../assets/profile_img.png";
import Down from "../../assets/down.png";
import Cv from "../../assets/Ender CV.pdf"
import { init } from "ityped";
import { useEffect, useRef } from "react";
import { Typewriter } from "react-simple-typewriter";

export default function Intro() {
  const textRef = useRef();

  // useEffect(() => {
  //   init(textRef.current, {
  //     showCursor: true,
  //     backDelay: 1500,
  //     backSpeed: 60,
  //     strings: ["Sharmut", "Khab", "Manyak"],
  //   });
  // }, []);

  return (
    <div className="intro" id="intro">
      <div className="left">
        <div className="imgContainer">
          <img src={prof} />
        </div>
      </div>
      <div className="right">
        <div className="wrapper">
          <h2>Hi, ik ben</h2>
          <h1>Ender Yürük</h1>
          <h3>
            Technologieën: 
            <span>
              <Typewriter
                words={[
                  " React",

                  " HTML",

                  " CSS - SASS",

                  " JavaScript ",

                  " Git - GitHub",

                  " React-Native ",

                  " Node.Js ",
                ]}
                cursor
                loop
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
          </h3>
          <a href={Cv} download className="cv"><h4>Download CV</h4></a>
        </div>
        <a href="#portfolio">
          <img src={Down} />
        </a>
      </div>
    </div>
  );
}
